import { render, staticRenderFns } from "./deploySetting.vue?vue&type=template&id=521ef397&scoped=true&"
import script from "./deploySetting.vue?vue&type=script&lang=js&"
export * from "./deploySetting.vue?vue&type=script&lang=js&"
import style0 from "./deploySetting.vue?vue&type=style&index=0&id=521ef397&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "521ef397",
  null
  
)

export default component.exports